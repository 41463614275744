import { GatsbySeo } from 'gatsby-plugin-next-seo'
import BuscaVazia from 'src/components/product/BuscaVazia'
import RenderCMS from 'src/components/RenderCMS'
import { tempData } from 'src/components/sections/Blog/tempData'

// const useErrorState = (location: Location) =>
//   useMemo(() => {
//     const params = new URLSearchParams(location.search)
//     const fromUrl = decodeURI(params.get('from') ?? location.pathname)

//     return {
//       fromUrl,
//     }
//   }, [location.pathname, location.search])

function Page() {
  // const { fromUrl } = useErrorState(location)

  // console.log(fromUrl)

  return (
    <>
      <GatsbySeo noindex nofollow />

      {/* <h1>Not Found: 404</h1>
      <div>This app could not find url {fromUrl}</div> */}
      <BuscaVazia showBreadcrumb />

      <RenderCMS
        sections={[
          {
            name: 'ProductShelf',
            data: {
              clusterId: 137,
              shelfTitle: 'Você também vai gostar',
              cmsId: 'buscavazia',
              productsQuantity: 8,
            },
          },
          {
            name: 'Blog',
            data: tempData,
          },
          {
            name: 'Newsletter',
            data: {},
          },
        ]}
      />
    </>
  )
}

export default Page
